import { defineComponent, Ref, ref } from '@vue/composition-api';

export default defineComponent({
	setup() {
		const apiList: Ref<any[]> = ref([
			{
				onlyid: 'qq',
				title: 'QQ信息接口',
				description: '获取QQ昵称和头像API',
				author: '悠悠',
				url: 'https://www.usuuu.com/post/1591986508842143745?https://www.usuuu.com/api/qq',
			},
			{
				onlyid: 'ip',
				title: 'IP地址接口',
				description: '获取IP地址和位置API',
				author: '悠悠',
				url: 'https://www.usuuu.com/post/1591985613903499266?https://www.usuuu.com/api/ip',
			},
			{
				onlyid: '163',
				title: '网易云音乐接口',
				description: '根据歌曲ID返回歌曲信息',
				author: '悠悠',
				url: 'https://www.usuuu.com/post/1591986878335160321?https://www.usuuu.com/api/music',
			},
		]);
		return { apiList };
	},
});
